interface Environment {
  apiBaseUrl: string;
  stripeKey: string;
}

const environment: Environment = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  stripeKey: process.env.REACT_APP_STRIPE_KEY || '',
};

export default environment;
