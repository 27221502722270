import { useQuery } from "@tanstack/react-query";
import { getAllExperiences, getExperience } from "../apis/experienceApi";

export const useExperience = (id: string) =>
  useQuery({ queryKey: ["experience", id], queryFn: () => getExperience(id) });

export const useAllExperiences = (query?: any) =>
  useQuery({
    queryKey: ["experiences", JSON.stringify(query)],
    queryFn: () => getAllExperiences(query),
  });
