import { Box, Button, Typography } from "@mui/material";
import { Widget } from "@typeform/embed-react";
import { Link, useNavigate } from "react-router-dom";
import LogoSVG from "../assets/images/logo-white.svg";
import useIsMobile from "../hooks/useIsMobile";

export default function TermsPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Box
      height={!isMobile ? "100vh" : undefined}
      bgcolor="#000"
      px={isMobile ? 2 : 6}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={3}
      >
        <Box onClick={() => navigate("/")}>
          <div>
            <img src={LogoSVG} alt="icon " width="90" />
          </div>
        </Box>
        <Box>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => navigate("/")}
          >
            Regresar
          </Button>
        </Box>
      </Box>
      <Box height={!isMobile ? "calc(100% - 125px)" : "100%"}>
        <Box
          overflow="auto"
          bgcolor="#f4f1ed"
          height="100%"
          px={4}
          py={2}
          boxSizing="border-box"
        >
          <Typography variant="h6">
            Elimina tu cuenta y datos de Traveria
          </Typography>
          <br />
          <Typography variant="body2">
            En Traveria, valoramos tu privacidad y seguridad de datos. Si deseas
            eliminar tu cuenta y la información asociada, respetamos tu decisión
            y estamos aquí para ayudarte en cada paso del proceso. Para iniciar
            el proceso de eliminación de la cuenta, por favor completa el
            formulario que se encuentra a continuación. Al hacerlo, garantizarás
            la eliminación de tu cuenta y cualquier dato relacionado de nuestro
            sistema. Tu solicitud será procesada de manera rápida, de acuerdo
            con nuestras políticas de privacidad.
          </Typography>
          <br />
          <Typography variant="subtitle1"> Qué se elimina:</Typography>
          <Typography variant="body2">
            <ul>
              <li>
                Información Personal: Nombre, Correo Electrónico, Numero de
                Teléfono, Foto de Perfil, Información de Pago, Contraseña
              </li>
              <li>Datos de Uso: Reservaciones Realizadas</li>
              <li>Cualquier Otro Dato Asociado con Tu Cuenta</li>
            </ul>
          </Typography>
          <Typography variant="subtitle1">
            Período de retención de datos:
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>
                Datos Eliminados: Tu información personal y datos de uso serán
                eliminados permanentemente de nuestro sistema al procesar tu
                solicitud.
              </li>
            </ul>
          </Typography>
          <Widget
            id="rJ72dTiO"
            style={{
              height: "50%",
              width: isMobile ? "100%" : "50%",
              marginTop: 16,
            }}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={1}>
        <Typography variant="caption" color="primary">
          © Traveria 2024 • <Link to="/privacy">Privacidad</Link> •{" "}
          <Link to="/terms">Términos</Link>
        </Typography>
      </Box>
    </Box>
  );
}
