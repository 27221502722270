import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LogoSVG from "../../assets/images/logo-white.svg";
import useIsMobile from "../../hooks/useIsMobile";

export default function TermsPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Box
      height={!isMobile ? "100vh" : undefined}
      bgcolor="#000"
      px={isMobile ? 2 : 6}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={3}
      >
        <Box onClick={() => navigate("/")}>
          <div>
            <img src={LogoSVG} alt="icon " width="90" />
          </div>
        </Box>
        <Box>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => navigate("/")}
          >
            Regresar
          </Button>
        </Box>
      </Box>
      <Box height={!isMobile ? "calc(100% - 125px)" : "100%"}>
        <Box
          overflow="auto"
          bgcolor="#f4f1ed"
          height="100%"
          px={4}
          py={2}
          boxSizing="border-box"
        >
          <Typography variant="h6">
            Términos y condiciones de la plataforma
          </Typography>
          <br />
          <Typography variant="subtitle2">1. Introducción</Typography>
          <Typography variant="body2">
            Estos términos y condiciones (los "Términos") establecen los
            términos y condiciones para el uso de la plataforma ("la
            plataforma") ofrecida por Traveria ("nosotros", "nuestro", "nos"), y
            se aplican tanto a los usuarios que utilizan la plataforma como a
            los anfitriones que ofrecen experiencias y actividades en ella.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            2. Aceptación de los términos
          </Typography>
          <Typography variant="body2">
            Al acceder y utilizar la plataforma, usted acepta estos Términos y
            acepta cumplir con ellos. Si no acepta estos Términos, no utilice la
            plataforma.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            3. Servicios de la plataforma
          </Typography>
          <Typography variant="body2">
            La plataforma permite a los usuarios explorar, reservar y pagar por
            experiencias y actividades ofrecidas por anfitriones en diferentes
            lugares del mundo.
          </Typography>
          <br />
          <Typography variant="subtitle2">4. Anfitriones</Typography>
          <Typography variant="body2">
            Los anfitriones son responsables de la creación, publicación y
            cumplimiento de sus experiencias y actividades. Los anfitriones son
            los únicos responsables de proporcionar experiencias seguras,
            legales y adecuadas para su público.
          </Typography>
          <br />
          <Typography variant="subtitle2">5. Usuarios</Typography>
          <Typography variant="body2">
            Los usuarios pueden explorar, reservar y pagar por experiencias y
            actividades en la plataforma. Los usuarios son responsables de su
            propia seguridad y bienestar durante la experiencia o actividad
            reservada.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            6. Política de cancelación
          </Typography>
          <Typography variant="body2">
            Los anfitriones pueden establecer su propia política de cancelación
            para sus experiencias y actividades. Los usuarios deben leer
            cuidadosamente la política de cancelación del anfitrión antes de
            reservar.
          </Typography>
          <br />
          <Typography variant="subtitle2">7. Pago</Typography>
          <Typography variant="body2">
            Los usuarios pueden pagar por experiencias y actividades a través de
            la plataforma. Nosotros cobraremos al usuario el precio total de la
            experiencia o actividad en el momento de la reserva. El pago se
            procesará a través de un procesador de pagos de terceros.
          </Typography>
          <br />
          <Typography variant="subtitle2">8. Propiedad intelectual</Typography>
          <Typography variant="body2">
            La plataforma y todo su contenido, incluyendo, entre otros, los
            textos, gráficos, logotipos, imágenes, videos, software y otros
            materiales (colectivamente, el "Contenido de la plataforma") son
            propiedad de Traveria o de sus licenciantes y están protegidos por
            las leyes de propiedad intelectual aplicables.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            9. Derechos de autor y marca registrada
          </Typography>
          <Typography variant="body2">
            Los derechos de autor, marcas registradas y otros derechos de
            propiedad intelectual relacionados con el Contenido de la plataforma
            son propiedad de Traveria o de sus licenciantes. Usted no puede
            utilizar el Contenido de la plataforma sin el permiso previo por
            escrito de Traveria.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            10. Exclusión de garantías
          </Typography>
          <Typography variant="body2">
            La plataforma se proporciona "tal cual" y sin garantía de ningún
            tipo, ya sea expresa o implícita. Traveria no garantiza la
            exactitud, integridad, calidad, fiabilidad o adecuación para un
            propósito particular de la plataforma.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            11. Limitación de responsabilidad
          </Typography>
          <Typography variant="body2">
            En ningún caso Traveria será responsable de ningún daño directo,
            indirecto, incidental, especial o consecuencial que surja del uso o
            la imposibilidad de uso de la plataforma.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            12. Modificaciones a los Términos
          </Typography>
          <Typography variant="body2">
            Nos reservamos el derecho de modificar estos Términos en cualquier
            momento, a nuestra discreción y sin previo aviso. Cualquier
            modificación será efectiva inmediatamente después de su publicación
            en la plataforma. Su uso continuado de la plataforma después de
            cualquier modificación de estos Términos constituye su aceptación de
            los Términos modificados.
          </Typography>
          <br />
          <Typography variant="subtitle2">13. Terminación</Typography>
          <Typography variant="body2">
            Nos reservamos el derecho de terminar su acceso a la plataforma en
            cualquier momento y por cualquier motivo, sin previo aviso. Si se
            viola algún término de estos Términos, su acceso a la plataforma
            puede ser terminado inmediatamente sin previo aviso.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            14. Ley aplicable y jurisdicción
          </Typography>
          <Typography variant="body2">
            Estos Términos se regirán e interpretarán de acuerdo con las leyes
            del país donde se encuentra registrada la empresa Traveria.
            Cualquier disputa que surja de o en relación con estos Términos
            estará sujeta a la jurisdicción exclusiva de los tribunales de dicha
            jurisdicción.
          </Typography>
          <br />
          <Typography variant="subtitle2">15. Acuerdo completo</Typography>
          <Typography variant="body2">
            Estos Términos representan el acuerdo completo entre usted y
            Traveria en relación con su uso de la plataforma y reemplazan
            cualquier acuerdo previo o contemporáneo entre usted y Traveria.
          </Typography>
          <br />
          <Typography variant="body2">
            Si tienes alguna pregunta sobre estos términos y condiciones, por
            favor contáctanos.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={1}>
        <Typography variant="caption" color="primary">
          © Traveria 2024 • <Link to="/privacy">Privacidad</Link> •{" "}
          <Link to="/terms">Términos</Link>
        </Typography>
      </Box>
    </Box>
  );
}
