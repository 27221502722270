import { Box, Button, Grid, Typography } from "@mui/material";
import { Sidetab } from "@typeform/embed-react";
import { Link } from "react-router-dom";
import AppScreenMobilePNG from "../assets/images/app-screen-mobile.png";
import AppScreenMobileWEBP from "../assets/images/app-screen-mobile.webp";
import AppScreenPNG from "../assets/images/app-screen.png";
import AppScreenWEBP from "../assets/images/app-screen.webp";
import AppStoreBadgePNG from "../assets/images/app-store-badge.png";
import AppStoreBadgeWEBP from "../assets/images/app-store-badge.webp";
import GooglePlayBadgePNG from "../assets/images/google-play-badge.png";
import GooglePlayBadgeWEBP from "../assets/images/google-play-badge.webp";
import LogoSVG from "../assets/images/logo-white.svg";
import useIsMobile from "../hooks/useIsMobile";

export default function LandingPage() {
  const isMobile = useIsMobile();

  return (
    <Box
      height={!isMobile ? "100vh" : undefined}
      bgcolor="#000"
      px={isMobile ? 2 : 6}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={3}
      >
        <Box>
          <div>
            <img src={LogoSVG} alt="logo" width="120" />
          </div>
        </Box>
        <Box>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => {
              const sidetabButton = document.querySelector<HTMLButtonElement>(
                ".tf-v1-sidetab-button"
              );

              sidetabButton?.click();
            }}
          >
            Quiero ser anfitrión
          </Button>
        </Box>
      </Box>
      <Box height={!isMobile ? "calc(100% - 125px)" : "100%"}>
        <Box
          bgcolor="#f4f1ed"
          height="100%"
          borderRadius={7}
          px={10}
          p={isMobile ? 4 : undefined}
        >
          <Grid container alignItems="center" height="100%">
            {isMobile && (
              <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                justifyContent="center"
                height="100%"
                mb={2}
              >
                <picture>
                  <source srcSet={AppScreenMobileWEBP} type="image/webp" />
                  <img src={AppScreenMobilePNG} alt="app screen" width="100%" />
                </picture>
              </Grid>
            )}
            <Grid item container xs={9} sm={6} rowSpacing={isMobile ? 2 : 4}>
              <Grid item>
                <Typography variant="h3" fontWeight="bold">
                  Prepárate para embarcarte en tu próxima gran aventura.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  Descubre nuevos caminos, explora la naturaleza y conecta con
                  personas extraordinarias como tú.
                </Typography>
              </Grid>
              <Grid container item alignItems="center" columnSpacing={2} mt={4}>
                <Grid item>
                  <picture>
                    <source srcSet={AppStoreBadgeWEBP} type="image/webp" />
                    <a
                      rel="noreferrer"
                      href="https://apps.apple.com/us/app/kuest/id6446014595"
                      target="_blank"
                    >
                      <img
                        src={AppStoreBadgePNG}
                        alt="descargar de app store"
                        width="160px"
                        style={{ objectPosition: "50% 50%" }}
                      />
                    </a>
                  </picture>
                </Grid>
                <Grid item>
                  <picture>
                    <source srcSet={GooglePlayBadgeWEBP} type="image/webp" />
                    <a
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=mx.com.kuest.app"
                      target="_blank"
                    >
                      <img
                        src={GooglePlayBadgePNG}
                        alt="descargar de google play"
                        width="180px"
                        style={{ objectPosition: "50% 50%" }}
                      />
                    </a>
                  </picture>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid
                container
                item
                xs={12}
                sm={6}
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <picture>
                  <source srcSet={AppScreenWEBP} type="image/webp" />
                  <img
                    src={AppScreenPNG}
                    alt="app screen"
                    height="550px"
                    style={{ objectPosition: "50% 50%" }}
                  />
                </picture>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={1}>
        <Typography variant="caption" color="primary">
          © Traveria 2024 • <Link to="/privacy">Privacidad</Link> •{" "}
          <Link to="/terms">Términos</Link>
        </Typography>
      </Box>
      <Sidetab
        id="DoMRCl15"
        buttonText="Quiero ser anfitrión"
        buttonColor="#000"
      />
    </Box>
  );
}
