import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LogoSVG from "../../assets/images/logo-white.svg";
import useIsMobile from "../../hooks/useIsMobile";

export default function PrivacyPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <Box
      height={!isMobile ? "100vh" : undefined}
      bgcolor="#000"
      px={isMobile ? 2 : 6}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={3}
      >
        <Box onClick={() => navigate("/")}>
          <div>
            <img src={LogoSVG} alt="icon " width="90" />
          </div>
        </Box>
        <Box>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => navigate("/")}
          >
            Regresar
          </Button>
        </Box>
      </Box>
      <Box height={!isMobile ? "calc(100% - 125px)" : "100%"}>
        <Box
          overflow="auto"
          bgcolor="#f4f1ed"
          height="100%"
          px={4}
          py={2}
          boxSizing="border-box"
        >
          <Typography variant="h6">
            Aviso de privacidad para la plataforma
          </Typography>
          <br />
          <Typography variant="body2">
            En Traveria, nos tomamos muy en serio la privacidad y protección de
            sus datos personales. Este aviso de privacidad (el "Aviso") explica
            cómo recopilamos, usamos, divulgamos y protegemos la información
            personal que nos proporciona a través de la plataforma Traveria (la
            "Plataforma").
          </Typography>
          <br />
          <Typography variant="subtitle2">
            1. Información que recopilamos
          </Typography>
          <Typography variant="body2">
            Recopilamos información personal sobre usted cuando utiliza la
            Plataforma, incluyendo:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2">
                Información de registro: Nombre, dirección de correo electrónico
                y otra información de contacto.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Información de pago: Información de facturación y pago para
                procesar los pagos por los servicios que se ofrecen en la
                Plataforma.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Información de perfil: Información de perfil como su nombre,
                foto de perfil, biografía y preferencias de viaje.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Información de comunicación: Los correos electrónicos, mensajes
                y otra información que nos envía o intercambia con nosotros,
                incluyendo comentarios y preguntas que nos haga.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Información técnica: Información técnica como la dirección IP,
                tipo de dispositivo y sistema operativo que utiliza para acceder
                a la Plataforma.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle2">2. Uso de la información</Typography>
          <Typography variant="body2">
            Usamos la información personal que recopilamos para:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2">
                Procesar pagos y reservas en la Plataforma.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Ofrecer soporte técnico y de atención al cliente.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Personalizar la experiencia del usuario en la Plataforma.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Comunicarnos con usted sobre la Plataforma y nuestros servicios.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                {" "}
                Mejorar y optimizar la Plataforma y nuestros servicios.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle2">
            3. Divulgación de la información
          </Typography>
          <Typography variant="body2">
            Podemos compartir su información personal con terceros en las
            siguientes circunstancias:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2">
                Para procesar pagos y reservas en la Plataforma.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Para cumplir con las leyes y regulaciones aplicables.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Para prevenir el fraude o la actividad ilegal.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Para proteger los derechos, propiedad o seguridad de Traveria,
                sus usuarios o el público en general.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle2">
            4. Protección de la información
          </Typography>
          <Typography variant="body2">
            Tomamos medidas de seguridad para proteger la información personal
            que recopilamos, incluyendo la implementación de medidas técnicas y
            organizativas para proteger su información personal contra el acceso
            no autorizado, la divulgación, el uso indebido o la pérdida.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            5. Retención de la información
          </Typography>
          <Typography variant="body2">
            Mantenemos su información personal sólo durante el tiempo que sea
            necesario para los fines para los que fue recopilada o según lo
            requiera la ley aplicable.
          </Typography>
          <br />
          <Typography variant="subtitle2">6. Derechos de privacidad</Typography>
          <Typography variant="body2">
            De acuerdo con las leyes de protección de datos aplicables, usted
            tiene ciertos derechos relacionados con su información personal.
            Estos derechos pueden incluir:
          </Typography>
          <ul>
            <li>
              <Typography variant="body2">
                Derecho a acceder a su información personal.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Derecho a solicitar la corrección de su información personal.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Derecho a solicitar la eliminación de su información personal.
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Derecho a oponerse al procesamiento de su información personal.
              </Typography>
            </li>
          </ul>
          <Typography variant="body2">
            Para ejercer estos derechos o para cualquier otra pregunta o
            inquietud relacionada con la privacidad, por favor contáctenos a
            través de legal@traveria.com.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            7. Modificaciones al aviso de privacidad
          </Typography>
          <br />
          <Typography variant="body2">
            Nos reservamos el derecho de modificar este Aviso de privacidad en
            cualquier momento y a nuestra discreción. Cualquier modificación
            será efectiva inmediatamente después de su publicación en la
            Plataforma. Su uso continuado de la Plataforma después de cualquier
            modificación de este Aviso de privacidad constituye su aceptación de
            los términos modificados.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            8. Ley aplicable y jurisdicción
          </Typography>
          <br />
          <Typography variant="body2">
            Este Aviso de privacidad se rige e interpreta de acuerdo con las
            leyes del país donde se encuentra registrada la empresa Traveria.
            Cualquier disputa que surja de o en relación con este Aviso de
            privacidad estará sujeta a la jurisdicción exclusiva de los
            tribunales de dicha jurisdicción.
          </Typography>
          <br />
          <Typography variant="body2">
            Si tiene alguna pregunta o inquietud sobre este Aviso de privacidad,
            por favor contáctenos a través de legal@traveria.com.
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={1}>
        <Typography variant="caption" color="primary">
          © Traveria 2024 • <Link to="/privacy">Privacidad</Link> •{" "}
          <Link to="/terms">Términos</Link>
        </Typography>
      </Box>
      ‚
    </Box>
  );
}
