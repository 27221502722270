import axios, { AxiosInstance } from "axios";
import environment from "../environment";

const Axios = (function () {
  let instance: AxiosInstance;

  function createInstance() {
    const axiosInstance = axios.create({
      baseURL: environment.apiBaseUrl,
    });

    return axiosInstance;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }

      return instance;
    },
  };
})();

export default Axios.getInstance();
