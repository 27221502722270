import axios from "../clients/axios";
import { GetAllExperience } from "../models/getAllExperiences";
import { GetExperience } from "../models/getExperience";

export const getExperience = async (id: string): Promise<GetExperience> => {
  const response = await axios.get<GetExperience>(`/experiences/${id}`);

  return response.data;
};

export const getAllExperiences = async (
  query?: any
): Promise<GetAllExperience[]> => {
  const response = await axios.get<GetAllExperience[]>(`/experiences`, {
    params: query,
  });

  return response.data;
};
