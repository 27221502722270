import { Box, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

function SwipeableTextMobileStepper({
  images,
  height = 390,
  onClick = () => {},
}: any) {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <Box position="relative" onClick={onClick}>
      <Swiper
        onSlideChange={(swiper) => setActiveStep(swiper.activeIndex + 1)}
        modules={[Pagination, EffectFade, Autoplay]}
        effect="fade"
        autoplay={{ delay: 5000 }}
      >
        {images.map((image: any, index: any) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%), url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              height={height}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        component={Paper}
        position="absolute"
        borderRadius={2}
        top={12}
        right={12}
        zIndex={1}
        elevation={2}
        px={1}
        bgcolor="rgba(0, 0, 0, 0.4)"
        color="white"
      >
        <Typography variant="caption" fontWeight={600}>
          {activeStep} de {images.length} fotos
        </Typography>
      </Box>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
