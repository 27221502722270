import { CalendarMonth, SentimentVeryDissatisfied } from "@mui/icons-material";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppStoreBadgePNG from "../../assets/images/app-store-badge.png";
import AppStoreBadgeWEBP from "../../assets/images/app-store-badge.webp";
import GooglePlayBadgePNG from "../../assets/images/google-play-badge.png";
import GooglePlayBadgeWEBP from "../../assets/images/google-play-badge.webp";
import LogoSVG from "../../assets/images/logo-white.svg";
import SwipeableTextMobileStepper from "../../components/SwipeableTextMobileStepper";
import useIsMobile from "../../hooks/useIsMobile";
import { useExperience } from "../../queries/experiences";

export default function ExperiencePage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: experience, isLoading, error } = useExperience(id!);

  if (isLoading) {
    return (
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={1}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !experience) {
    return (
      <Box
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={1}
      >
        <SentimentVeryDissatisfied fontSize="large" />
        <Typography textAlign="center" variant="subtitle1">
          Ops! Algo salió mal, intenta de nuevo.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      height={!isMobile ? "100vh" : undefined}
      bgcolor="#000"
      px={isMobile ? 2 : 6}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        py={3}
      >
        <Box onClick={() => navigate("/")}>
          <div>
            <img src={LogoSVG} alt="icon " width="90" />
          </div>
        </Box>
      </Box>
      <Box height={!isMobile ? "calc(100% - 125px)" : "100%"}>
        <Box
          overflow="auto"
          bgcolor="#f4f1ed"
          height="100%"
          boxSizing="border-box"
          position="relative"
          p={{ md: 4 }}
        >
          <Grid container justifyContent="center">
            <Grid item container xs={12} md={6} bgcolor="white">
              <Grid item xs={12}>
                <SwipeableTextMobileStepper
                  images={[
                    experience.cover,
                    ...experience.documentCollection.documents.map(
                      (document) => document.uri
                    ),
                  ]}
                  height={320}
                />
              </Grid>
              <Grid item container xs={12} p={4} gap={2}>
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    {experience.title}
                  </Typography>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="subtitle1" fontWeight={600}>
                      {experience.location.city}, {experience.location.region}
                    </Typography>
                  </Box>
                  <Typography variant="caption" fontWeight={600}>
                    por {experience.host.displayName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={600} mb={2}>
                    Fechas disponibles
                  </Typography>
                  <Box
                    display="flex"
                    gap={1}
                    overflow="auto"
                    className="hideScrollbars"
                  >
                    {experience.events.map((event) => {
                      return (
                        <Box
                          flexShrink={0}
                          component={Paper}
                          key={event.id}
                          py={2}
                          px={3}
                          borderRadius={5}
                          variant="outlined"
                        >
                          <Box display="flex" flexDirection="column" gap={2}>
                            <CalendarMonth fontSize="small" />
                            <Box>
                              <Typography
                                variant="body1"
                                fontWeight={600}
                                textTransform="capitalize"
                              >
                                {format(
                                  new Date(event.startsAt),
                                  "EEEE d LLL",
                                  { locale: esLocale }
                                )}
                              </Typography>
                              <Typography
                                variant="caption"
                                fontWeight={500}
                                textTransform="capitalize"
                              >
                                {format(new Date(event.startsAt), "h:mm a", {
                                  locale: esLocale,
                                })}{" "}
                                -{" "}
                                {format(new Date(event.endsAt), "h:mm a", {
                                  locale: esLocale,
                                })}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight="bold">
                    ¡Descarga nuestra app para unirte a la experiencia!
                  </Typography>
                </Grid>
                <Grid container item alignItems="center" columnSpacing={2}>
                  <Grid item>
                    <picture>
                      <source srcSet={AppStoreBadgeWEBP} type="image/webp" />
                      <a
                        rel="noreferrer"
                        href="https://apps.apple.com/us/app/kuest/id6446014595"
                        target="_blank"
                      >
                        <img
                          src={AppStoreBadgePNG}
                          alt="descargar de app store"
                          width="160px"
                          style={{ objectPosition: "50% 50%" }}
                        />
                      </a>
                    </picture>
                  </Grid>
                  <Grid item>
                    <picture>
                      <source srcSet={GooglePlayBadgeWEBP} type="image/webp" />
                      <a
                        rel="noreferrer"
                        href="https://play.google.com/store/apps/details?id=mx.com.kuest.app"
                        target="_blank"
                      >
                        <img
                          src={GooglePlayBadgePNG}
                          alt="descargar de google play"
                          width="180px"
                          style={{ objectPosition: "50% 50%" }}
                        />
                      </a>
                    </picture>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" width="100%" mt={1}>
        <Typography variant="caption" color="primary">
          © Traveria 2024 • <Link to="/privacy">Privacidad</Link> •{" "}
          <Link to="/terms">Términos</Link>
        </Typography>
      </Box>
      ‚
    </Box>
  );
}
